import {
  CmsBannerSectionAsEnum,
  CmsBannerSectionLayoutEnum,
  CmsBannerTile,
} from 'framework/strapi/types'
import React, { FC } from 'react'
import cn from 'classnames'
import Background from '../../common/Background'
import ButtonWrapper from 'components/ui/ButtonWrapper'
import Button from 'components/ui/Button'
import { textLineBreaks } from 'utils/textLineBreaks'
import { breakPoint } from 'utils/breakpoints'
import { useAppSelector } from 'store'
import backgroundIsCovered from 'utils/backgroundIsCovered'

interface Props extends CmsBannerTile {
  tileCount: number
  bannerLayout: CmsBannerSectionLayoutEnum
  index: number
  as: CmsBannerSectionAsEnum
}

const BannerTile: FC<Props> = ({
  layout,
  background,
  tileCount,
  colorTheme,
  bannerLayout,
  index,
  overline,
  heading,
  text,
  ctas,
  as,
}) => {
  const locale = useAppSelector((s) => s.channel.locale)

  let sizes = '100vw'
  const tileCss = {
    tile: 'relative flex flex-col flex-1 justify-end',
    container:
      'relative px-6 pt-10 md:px-14 md:pt-14 lg:px-20 flex flex-col gap-y-7 md:gap-y-10',
    headingTextWrapper: 'flex flex-col gap-y-4 md:gap-y-5',
    headingWrapper: 'flex flex-col gap-y-2',
    overline: 'font-medium text-h-sm md:text-h-base',
    heading:
      tileCount > 1
        ? 'font-bold text-h-3xl md:text-h-5xl lg:text-h-4xl xl:text-h-4xl 2xl:text-h-5xl max-w-md md:max-w-lg xl:max-w-xl 2xl:max-w-2xl'
        : 'font-bold text-h-3xl md:text-h-5xl lg:text-h-6xl xl:text-h-7xl max-w-md md:max-w-lg lg:max-w-2xl xl:max-w-3xl',
    text:
      tileCount > 1
        ? 'max-w-md font-medium leading-normal text-base md:text-lg 2xl:text-xl 2xl:leading-normal'
        : 'max-w-md font-medium leading-normal text-base md:text-lg xl:text-xl xl:leading-normal',
  }

  // Tile Layout
  if (layout === 'Mega') {
    tileCss.overline =
      'font-medium text-h-sm md:text-h-base xl:text-h-lg 2xl:text-h-xl'
    tileCss.headingWrapper = 'flex flex-col gap-y-2 lg:gap-y-4 2xl:gap-y-5'
    tileCss.headingTextWrapper = 'flex flex-col gap-y-8 md:gap-y-10'
    tileCss.heading =
      'font-bold text-h-3xl sm:text-h-5xl md:text-h-6xl lg:text-h-7xl xl:text-h-8xl 2xl:text-h-9xl max-w-md md:max-w-xl lg:max-w-3xl xl:max-w-5xl 2xl:max-w-6xl lg:-mt-2'
    tileCss.text =
      'max-w-md md:max-w-lg font-medium leading-normal text-base md:text-xl'
  }

  const isBackgroundVisible = backgroundIsCovered(locale, background)
  // Tile Has Background
  if (isBackgroundVisible) {
    tileCss.container =
      'w-full relative px-6 pt-28 pb-10 md:pt-28 md:pb-14 md:px-14 lg:pt-30 lg:pb-20 lg:px-20 flex flex-col gap-y-7 md:gap-y-10'

    // sectionIsCovered = true;
  }

  switch (colorTheme) {
    case 'Dark':
      tileCss.overline = cn(tileCss.overline, 'text-white')
      tileCss.heading = cn(tileCss.heading, 'text-white')
      tileCss.text = cn(tileCss.text, 'text-white')
      break
    default:
      tileCss.overline = cn(tileCss.overline, 'text-primary-2')
      tileCss.heading = cn(tileCss.heading, 'text-primary')
      tileCss.text = cn(tileCss.text, 'text-primary')
  }

  // Section Layout
  if (bannerLayout === 'Grid') {
    tileCss.tile = 'relative flex items-end'
    if (tileCount === 3) {
      tileCss.tile =
        'relative flex items-end lg:col-start-1 lg:col-end-2 lg:row-start-1 lg:row-end-3'
      sizes = `(((min-width: ${breakPoint('lg')}) 50vw, 100vw)`

      if (index === 1) {
        tileCss.tile =
          'relative flex items-end lg:col-start-2 lg:col-end-3 lg:row-start-1 lg:row-end-2'
      }
      if (index === 2) {
        tileCss.tile =
          'relative flex items-end lg:col-start-2 lg:col-end-3 lg:row-start-2 lg:row-end-3'
      }
    } else if (tileCount === 2) {
      sizes = `(((min-width: ${breakPoint('lg')}) 50vw, 100vw)`
    }
  }
  // Tile Heading Tag
  const HeadingTag: any = as === 'Hero' && index === 0 ? 'h1' : 'h2'

  // Add line breaks to text
  text = textLineBreaks(text)

  return (
    <div key={index} className={tileCss.tile}>
      {isBackgroundVisible && <Background {...background!} sizes={sizes} />}
      <div className={tileCss.container}>
        <div className={tileCss.headingTextWrapper}>
          <div className={tileCss.headingWrapper}>
            {overline && <p className={tileCss.overline}>{overline}</p>}
            {heading && (
              <HeadingTag className={tileCss.heading}>{heading}</HeadingTag>
            )}
          </div>
          {text && (
            <p
              className={tileCss.text}
              dangerouslySetInnerHTML={{ __html: text }}
            />
          )}
        </div>
        {ctas && ctas.length > 0 && (
          <ButtonWrapper>
            {ctas.map((btn, index) => {
              return (
                btn.url.src && (
                  <Button
                    key={btn.id}
                    href={btn.url.src}
                    size="lg"
                    color={index === 0 ? 'primary' : 'secondary'}
                    colorTheme={colorTheme}
                  >
                    {btn.title}
                  </Button>
                )
              )
            })}
          </ButtonWrapper>
        )}
      </div>
    </div>
  )
}

export default BannerTile
