import Container from 'components/ui/Container'
import { CmsBannerSection } from 'framework/strapi/types'
import React, { FC } from 'react'
import BannerTile from './BannerTile'

interface Props extends CmsBannerSection {}

const Banner: FC<Props> = ({ tiles, layout, settings, as }) => {
  settings = settings || {}
  const { container } = settings

  // CSS
  const css = {
    tilesWrapper: 'flex-1 flex flex-col lg:flex-row',
  }

  if (!tiles || !tiles.length) return null

  if (tiles.length > 2) {
    css.tilesWrapper = 'flex-1 flex flex-col'
  }

  // Layout
  if (layout === 'Grid') {
    css.tilesWrapper = 'flex-1 grid grid-cols-1'

    if (tiles.length === 2) {
      css.tilesWrapper = 'flex-1 grid grid-cols-1 lg:grid-cols-2'
    }

    if (tiles.length === 3) {
      css.tilesWrapper = 'flex-1 grid grid-cols-1 lg:grid-cols-2 lg:grid-rows-2'
    }

    if (tiles.length === 4) {
      css.tilesWrapper = 'flex-1 grid grid-cols-1 lg:grid-cols-2 lg:grid-rows-2'
    }
  }

  return (
    <Container layout={container} height="Grow" direction="Row">
      <div className={css.tilesWrapper}>
        {tiles.map((tile, index) => (
          <BannerTile
            key={tile.id}
            {...tile}
            index={index}
            tileCount={tiles.length}
            as={as}
            bannerLayout={layout}
          />
        ))}
      </div>
    </Container>
  )
}

export default Banner
